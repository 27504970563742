import { makeStyles, Snackbar, SnackbarContent } from "@material-ui/core"
import React, { useState } from "react"

const useStyles = makeStyles({
    success: {
        backgroundColor: 'rgba(0,100,0,0.5)',
        color: 'white'
    },
    error: {
        backgroundColor: 'rgba(100,0,0,0.5)',
        color: 'white'
    }
})

export default function Notify({ message, type }) {

    const classes = useStyles()

    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Snackbar
            onClose={handleClose}
            open={open}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <SnackbarContent
                className={classes[type]}
                message={message}
            />
        </Snackbar>
    )
}