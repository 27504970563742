import { IconButton, Snackbar, SnackbarContent, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { navigate } from "gatsby"
import React, { useState, useEffect } from 'react';
import HordeManager from '../components/HordeAccount'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useLocation } from '@reach/router';
import Notification from '../components/Notification'
import * as api from '../api/calls'

import * as qs from 'query-string'

const HordePage = ({ location }) => {

  let lsUser
  if (typeof window !== `undefined`) lsUser = window.localStorage.getItem('userDetails')
  const [sessionID, setSessionID] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setUserDetails] = useState(lsUser && JSON.parse(lsUser))
  const [refresh, setRefresh] = useState(true)
  const [retryCounter, setRetryCounter] =  useState(0)
  const [myServers, setMyServers] = useState([])
  const [stoppedRefresh, setStopRefresh] = useState(false)


  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Horde Account Manager"

  const pageDesc = "Manage User Settings, Password, Maps Rotation for Horde Server"
  const retriesCount = 2

  const handleRefresh = () =>{
    setRefresh(!refresh)
  }

  const checkSession = async () => {
    const urlOptions = qs.parseUrl(location.href).query
    if (urlOptions.session_id) {
      // let userDetails
      // if (typeof window !== `undefined`) userDetails = JSON.parse(window.localStorage.getItem('userDetails'))
      const checkoutSession = await api.getCheckout(urlOptions.session_id);
      const customerID = checkoutSession.data.customer
      //Send customer ID to DB
      const updateRes = await api.updateUser(userDetails.user_name,userDetails.password, customerID)
      //Set local customerID
      const newUserDetails = {...userDetails, user_detail:{...userDetails.user_detail, stripeCustomerID:customerID}}
      setUserDetails(newUserDetails)
      if (typeof window !== `undefined`) window.localStorage.setItem('userDetails',JSON.stringify(newUserDetails))
      setSessionID(urlOptions.session_id)
      setTimeout(()=>{
        if (typeof window !== `undefined`) {
          window.location.href = 'https://pavlovhorde.com/horde'
          // window.location.reload()
        }
      },3000)
    }
  }

  const mergeArrays = (a, b, p) => a.filter( aa => ! b.find ( bb => aa[p] === bb[p]) ).concat(b)

  const checkLoggedIn = async () => {
    // let lsUser 
    // if (typeof window !== `undefined`) lsUser = window.localStorage.getItem('userDetails')
    try {
      // const userDetails = JSON.parse(lsUser)
      if (userDetails.user_name && userDetails.password && retryCounter < retriesCount) {
        let myUserDetails = await api.getUser(userDetails.user_name, userDetails.password)
            const serverList = await api.getAllServers()
            let theseServers = myUserDetails.user_perms.servers.map(s=> {
                const liveServer = serverList.find(x=>x.ip==s.ip)
                const oldServer = myServers.find(x=>x.ip==s.ip)
                const addServer = liveServer ? liveServer : {isBuilding:true}
                const joinedServer = {...s, ...addServer, ...oldServer}
                return joinedServer
            })
            // theseServers = theseServers.filter(s=>s.active)
            theseServers = theseServers.sort((a, b) => a.active && !b.active ? -1 : 1 )
            myUserDetails.user_perms.servers = theseServers
            myUserDetails =  {...userDetails, ...myUserDetails}
            setUserDetails(myUserDetails)
            setMyServers(theseServers)
            if (typeof window !== `undefined`) window.localStorage.setItem('userDetails', JSON.stringify(myUserDetails))
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    } catch (e) {
      setIsLoggedIn(false)
    }
  }

  useEffect(() => {
    checkLoggedIn()
    checkSession()
  },[refresh])

  return (
    <Layout setIsLoggedIn={setIsLoggedIn} pageTitle={pageTitle}>
      <SEO desc={pageDesc} title={pageTitle} />
      <IconButton
        onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h3" style={{ color: "white" }}>
        Horde Account Management
          </Typography>
      {sessionID && <Notification message='Thank you for your order! Please wait 15 mins and refresh.' type='success'/>}
      <HordeManager myServers={myServers} setMyServers={setMyServers} handleRefresh={handleRefresh} userDetails={userDetails} isLoggedIn={isLoggedIn}></HordeManager>
    </Layout>
  )
}

export default HordePage
